// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:100,200,300,400,500,600,700,800,900&display=swap');
@import url("//hello.myfonts.net/count/3ca4c8");

@font-face {
  font-family: 'NeueHaasUnicaW1G';
  src: url('../assets/fonts/NeueHaasUnicaW1G-Medium.woff2') format('woff2'), url('../assets/fonts/NeueHaasUnicaW1G-Medium.woff') format('woff');
  font-weight: 500;
}
// using medium again for bold or texts becomes ugly
@font-face {
  font-family: 'NeueHaasUnicaW1G';
  src: url('../assets/fonts/NeueHaasUnicaW1G-Medium.woff2') format('woff2'), url('../assets/fonts/NeueHaasUnicaW1G-Medium.woff') format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'NeueHaasUnicaW1G';
  src: url('../assets/fonts/NeueHaasUnicaW1G-Regular.woff2') format('woff2'), url('../assets/fonts/NeueHaasUnicaW1G-Regular.woff') format('woff');
  font-weight: 400;
}

@import '../../../node_modules/bootstrap/scss/functions';
@import 'theme/functions';
@import 'user-variables';
@import 'theme/variables';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/bootstrap';

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import 'theme/theme';

/*-----------------------------------------------
|   User Styles
-----------------------------------------------*/
@import 'user';
