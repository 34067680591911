//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

@include bg-variant('.bg-body', $body-bg, true);

.card-gutter-wrapper {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.card-gutter {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  //&:not(.d-flex) .card {
  //  min-height: 100%;
  //}
}
.color-home {
  color: #e4c07b;
}
.color-work {
  color: #56b6c2;
}
.color-custom {
  color: #c678dd;
}

%_badge-outline {
  background-color: transparent;
  color: #5e6e82;
}
.badge-large {
  padding: .6em 1em;
}
.badge-home {
  background-color: #e4c07b;
  color: #FFF;
}
.badge-home-outline {
  @extend %_badge-outline;
  border: 1px solid #e4c07b;
}
.badge-work {
  background-color: #56b6c2;
  color: #FFF;
}
.badge-work-outline {
  @extend %_badge-outline;
  border: 1px solid #56b6c2;
}
.badge-custom {
  background-color: #c678dd;
  color: #FFF;
}
.badge-custom-outline {
  @extend %_badge-outline;
  border: 1px solid #c678dd;
}

.table td {
  padding: 0.5rem;
}

.table-nospace.table thead > tr > th:first-child,
.table-nospace.table tr td:first-child,
.table-nospace.table tr th:first-child {
  padding-left: 0;
}

.table.value-center tbody tr td {
  text-align: center;
}
.table.value-center tbody tr td:first-child {
  text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

%_score-bar {
  height: 8px;
  border-radius: 25px;
}
.score-bar {
  @extend %_score-bar;
  position: relative;
  width: 100%;
  background-color: #d7f2e0;
}
.score-bar-value {
  @extend %_score-bar;
  position: absolute;
  left: 0;
  background-color: #5fcd82;
}
.popper-bottom {
  top: auto !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.calendar-right .rdtPicker {
  right: 0;
}

.chart-tooltip-point {
  display: inline-block;
  margin-right: 5px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.fa-spin {
  -webkit-animation: fa-spin 1.5s linear infinite;
  animation: fa-spin 1.5s linear infinite;
}

// demo mode
.demo-mode {
  .demo-blur {
    filter: blur(6px);
  }
}
